import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'index.css';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import { lightTheme } from 'theme';
import 'typeface-montserrat-alternates';
import { CssBaseline, ThemeProvider } from '@mui/material';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://59502033934845f0b3d0372ddb099814@o1058440.ingest.sentry.io/6060562',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={lightTheme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
