/* eslint-disable no-console */
// ToDo: @michal-stachnik replace console.errors with sentry logs
import React, {
  createContext, useCallback, useContext, useEffect, useState,
} from 'react';
import { CurrencyAmount, Token } from '@uniswap/sdk-core';
import { ethers, BigNumber, ContractReceipt } from 'ethers';
import { Web3Context, BorrowStage } from 'common-client';
import {
  ConfigContext, NetworkConfig, WrapDirection, Wrapper,
} from 'config';
import {
  approveRouter,
  approveWrapping,
  balanceOfMulti,
  buttonUnwrapFromBToken,
  buttonUnwrapFromUnderlying,
  buttonUnwrapWithRouter,
  buttonWrapFromBToken,
  buttonWrapFromUnderlying,
  buttonWrapWithRouter,
  unbuttonUnwrapFromUbToken,
  unbuttonUnwrapFromUnderyling,
  unbuttonWrapFromUbToken,
  unbuttonWrapFromUnderlying,
  wrapperToUnderlyingMulti,
} from 'transactions';

export type ExchangeProgress = {
  exchangeStep: BorrowStage;
  reason: any;
  value: any;
}

export type ExchangeRatio = {
  from: BigNumber;
  to: BigNumber;
}

const ButtonContext = createContext<{
  buttonWrap:(underylingAmount: string, buttonToken: Token) => Promise<CurrencyAmount<Token>>,
  wrapper: Wrapper,
  wrapDirection: WrapDirection,
  flipWrapDirection: () => void,
  inputCurrencyList: Token[],
  inputCurrency: Token | null,
  setInputCurrency: (inputCurrency: Token | null) => void,
  inputAmount: BigNumber | null;
  setInputAmount: (inputAmount: BigNumber | null) => void;
  outputCurrency: Token | null,
  outputAmount: BigNumber | null;
  setOutputAmount: (inputAmount: BigNumber | null) => void;
  inputCurrencyBalance: BigNumber | null;
  outputCurrencyBalance: BigNumber | null;
  currentExchangeRatio: ExchangeRatio | null;
  exchangeProgress: ExchangeProgress;
  approve: () => void;
  exchange: () => void;
  reset: () => void;
    }>({
      buttonWrap: () => Promise.reject(Error('Unitialized')),
      wrapper: Wrapper.button,
      wrapDirection: WrapDirection.wrapping,
      flipWrapDirection: () => {},
      inputCurrencyList: [],
      inputCurrency: null,
      setInputCurrency: () => {},
      inputAmount: null,
      setInputAmount: () => {},
      outputCurrency: null,
      outputAmount: null,
      setOutputAmount: () => {},
      inputCurrencyBalance: null,
      outputCurrencyBalance: null,
      currentExchangeRatio: null,
      exchangeProgress: { exchangeStep: 'start', reason: null, value: null },
      approve: () => {},
      exchange: () => {},
      reset: () => {},
    });

export type ButtonContextProps = {
  wrapper: Wrapper;
  children?: React.ReactNode;
};

const defaultProps: Partial<ButtonContextProps> = {
  children: null,
};

function getTokenPairs(
  wrapper: Wrapper,
  networkConfig: NetworkConfig | null,
): Map<WrapDirection, Map<Token, Token>> {
  return networkConfig?.pairs.get(wrapper) || new Map();
}

const ButtonProvider: React.FC<ButtonContextProps> = ({
  wrapper,
  children,
}: ButtonContextProps) => {
  const {
    ready,
    provider,
    multicallProvider,
    signer,
  } = useContext(Web3Context);
  const { networkConfig } = useContext(ConfigContext);

  const readSessionStorage = useCallback((key:string) => sessionStorage.getItem(`${wrapper}-${key}`), [wrapper]);
  const writeSessionStorage = useCallback((key:string, value: any) => sessionStorage.setItem(`${wrapper}-${key}`, value), [wrapper]);
  const deleteSessionStorage = useCallback((key:string) => sessionStorage.removeItem(`${wrapper}-${key}`), [wrapper]);
  const readPastOrDefault = useCallback(
    (key: string, updateState: (value: string) => void, defaultValue: any) => {
      const pastValue = readSessionStorage(key);
      if (pastValue) {
        updateState(pastValue);
      } else {
        updateState(defaultValue);
        writeSessionStorage(key, defaultValue);
      }
    }, [readSessionStorage, writeSessionStorage],
  );

  const [wrapDirection, setWrapDirection] = useState<WrapDirection>(WrapDirection.wrapping);

  useEffect(() => {
    readPastOrDefault('wrapDirection', (value: string) => setWrapDirection(value as WrapDirection), wrapDirection);
  }, []);

  const [tokenPairs, setTokenPairs] = useState<Map<WrapDirection, Map<Token, Token>>>(new Map());
  const [tokenBalances, setTokenBalances] = useState<Map<Token|null, BigNumber>|null>(null);
  const [
    exchangeRatios,
    setExchangeRatios,
  ] = useState<Map<Token|null, Map<Token|null, ExchangeRatio>>>(new Map());
  const [inputCurrency, setInputCurrency] = useState<Token|null>(null);
  const [outputCurrency, setOutputCurrency] = useState<Token|null>(null);
  const [inputAmount, setInputAmount] = useState<BigNumber|null>(null);
  const [outputAmount, setOutputAmount] = useState<BigNumber|null>(null);
  const [exchangeProgress, setExchangeProgress] = useState<ExchangeProgress>({
    reason: null,
    value: null,
    exchangeStep: 'start',
  });

  const currentExchangeRatio: ExchangeRatio | null = exchangeRatios
    ?.get(inputCurrency)?.get(outputCurrency) || null;
  const calculatedOutputAmount: BigNumber | null = outputAmount || (
    inputAmount && currentExchangeRatio
    && inputAmount.mul(currentExchangeRatio.to).div(currentExchangeRatio.from)
  );
  const calculatedInputAmount: BigNumber | null = inputAmount || (
    outputAmount && currentExchangeRatio
    && outputAmount.mul(currentExchangeRatio.from).div(currentExchangeRatio.to)
  );

  useEffect(() => {
    if (provider?.network?.chainId && ready) {
      const newTokenPairs = getTokenPairs(
        wrapper,
        networkConfig,
      );
      setTokenPairs(newTokenPairs);
    }
  }, [wrapper, wrapDirection, provider, provider?.network?.chainId, ready]);

  useEffect(() => {
    if (signer && multicallProvider) {
      const tokens: Token[] = [...tokenPairs.values()]
        .flatMap((tokenMaps) => [...tokenMaps.keys()]);
      balanceOfMulti(multicallProvider, signer, tokens)
        .then((balances) => {
          setTokenBalances(new Map(
            balances.map((balance: BigNumber, index: number) => [tokens[index], balance]),
          ));
        })
        .catch((reason) => console.error(reason));
    }
  }, [signer, multicallProvider, tokenPairs, exchangeProgress]);

  useEffect(() => {
    if (signer && multicallProvider) {
      const wrappingPairs = Array.from(
        tokenPairs.get(WrapDirection.wrapping) || new Map<Token, Token>(),
      );

      const unwrappedTokens = wrappingPairs.map((pair) => pair[0]);
      const wrappedTokens = wrappingPairs.map((pair) => pair[1]);
      // just checking the exchange rate against 1e18 units
      const amounts = wrappedTokens.map(() => ethers.utils.parseEther('1'));

      wrapperToUnderlyingMulti(multicallProvider, signer, amounts, wrappedTokens)
        .then((totalUnderlyings) => {
          const calculatedExchangeRatios: Map<Token, Map<Token, ExchangeRatio>> = new Map(
            totalUnderlyings.flatMap((totalUnderlying: BigNumber, index: number) => [
              [
                unwrappedTokens[index], new Map([
                  [wrappedTokens[index], ({ from: totalUnderlying, to: amounts[index] })],
                ]),
              ],
              [
                wrappedTokens[index], new Map([
                  [
                    unwrappedTokens[index],
                    ({ from: amounts[index], to: totalUnderlying }),
                  ],
                ]),
              ],
            ]),
          );
          setExchangeRatios(calculatedExchangeRatios);
        })
        .catch((reason) => console.error(reason));
    }
  }, [signer, multicallProvider, tokenPairs]);

  useEffect(() => {
    const inputCurrencyList = [...(tokenPairs.get(wrapDirection)?.keys() || [])];
    if (inputCurrencyList && inputCurrencyList.length) {
      const pastInputCurrencyAddress: string | null = readSessionStorage('inputCurrencyAddress');
      const nextInputCurrency = inputCurrencyList.find(
        (currency) => currency.address === pastInputCurrencyAddress,
      ) || inputCurrencyList[0];
      setInputCurrency(nextInputCurrency || null);
      writeSessionStorage('inputCurrencyAddress', nextInputCurrency.address);

      // Try to read inputAmount and outputAmount from sessionStorage
      const pastInputAmount: string | null = readSessionStorage('inputAmount');
      const nextInputAmount: BigNumber | null = (
        pastInputAmount && BigNumber.from(pastInputAmount)
      ) || null;
      const pastOutputAmount: string | null = readSessionStorage('outputAmount');
      const nextOutputAmount: BigNumber | null = (
        pastOutputAmount && BigNumber.from(pastOutputAmount)
      ) || null;

      // Only read/store one of either inputAmount or outputAmount variables
      if (nextInputAmount) {
        setInputAmount(nextInputAmount);
        writeSessionStorage('inputAmount', nextInputAmount.toString());
        deleteSessionStorage('outputAmount');
      } else if (nextOutputAmount) {
        setOutputAmount(nextOutputAmount);
        writeSessionStorage('outputAmount', nextOutputAmount.toString());
        deleteSessionStorage('inputAmount');
      } else {
        setInputAmount(null);
        writeSessionStorage('inputAmount', '');
        deleteSessionStorage('outputAmount');
      }
    } else {
      setInputCurrency(null);
    }
  }, [wrapDirection, tokenPairs]);

  const flipWrapDirection = useCallback(() => {
    if (inputCurrency && outputCurrency) {
      const nextWrapDirection = (wrapDirection === WrapDirection.wrapping)
        ? WrapDirection.unwrapping : WrapDirection.wrapping;
      setInputCurrency(outputCurrency);
      writeSessionStorage('inputCurrencyAddress', outputCurrency.address);

      if (inputAmount) {
        setInputAmount(null);
        setOutputAmount(inputAmount);
        writeSessionStorage('outputAmount', inputAmount || '');
        deleteSessionStorage('inputAmount');
      } else if (outputAmount) {
        setOutputAmount(null);
        setInputAmount(outputAmount);
        writeSessionStorage('inputAmount', outputAmount || '');
        deleteSessionStorage('outputAmount');
      }

      setWrapDirection(nextWrapDirection);
      writeSessionStorage('wrapDirection', nextWrapDirection);
    }
  }, [inputCurrency, outputCurrency, inputAmount, outputAmount, wrapDirection]);

  useEffect(() => {
    if (inputCurrency) {
      const nextOutputCurrency = tokenPairs.get(wrapDirection)?.get(inputCurrency) || null;
      setOutputCurrency(nextOutputCurrency);
    }
  }, [tokenPairs, wrapDirection, inputCurrency]);

  const approve = useCallback(() => {
    if (signer && ready && inputCurrency && calculatedInputAmount && outputCurrency) {
      const inputCurrencyAmount: CurrencyAmount<Token> = CurrencyAmount.fromRawAmount(
        inputCurrency, calculatedInputAmount.toString(),
      );

      setExchangeProgress({ reason: null, value: null, exchangeStep: 'collateralApproving' });
      if (wrapDirection === WrapDirection.wrapping) {
        approveWrapping(signer, inputCurrencyAmount, outputCurrency)
          .then((approval: boolean) => setExchangeProgress({
            reason: null,
            value: approval,
            exchangeStep: 'collateralApproved',
          }))
          .catch((reason) => setExchangeProgress({
            reason,
            value: null,
            exchangeStep: 'error',
          }));
      } else if (networkConfig?.wrapperRouters[outputCurrency.address]) {
        // note if the output is native ETH, we're sending through a router
        // which we need to approve to
        const router = networkConfig?.wrapperRouters[outputCurrency.address];

        approveRouter(signer, inputCurrencyAmount, router)
          .then((approval: boolean) => setExchangeProgress({
            reason: null,
            value: approval,
            exchangeStep: 'collateralApproved',
          }))
          .catch((reason) => setExchangeProgress({
            reason,
            value: null,
            exchangeStep: 'error',
          }));
      } else {
        setExchangeProgress({ reason: null, value: null, exchangeStep: 'collateralApproved' });
      }
    }
  }, [signer, ready, wrapper, wrapDirection, inputCurrency, calculatedInputAmount, outputCurrency]);

  const wrap: () => Promise<ContractReceipt> | null = useCallback(() => {
    if (!signer) return null;
    if (wrapper === Wrapper.button && inputCurrency
      && networkConfig?.wrapperRouters[inputCurrency.address]
      && calculatedInputAmount && outputCurrency) {
      // Wrapping with router (from underlying)
      return buttonWrapWithRouter(
        signer,
        calculatedInputAmount.toString(),
        outputCurrency,
        networkConfig.wrapperRouters[inputCurrency.address],
      );
    }
    if (inputAmount && outputCurrency) {
      // Wrapping from underlying
      return (wrapper === Wrapper.button)
        ? buttonWrapFromUnderlying(signer, inputAmount.toString(), outputCurrency)
        : unbuttonWrapFromUnderlying(signer, inputAmount.toString(), outputCurrency);
    }
    if (outputAmount && outputCurrency) {
      // Wrapping from bToken
      return (wrapper === Wrapper.button)
        ? buttonWrapFromBToken(
          signer,
          CurrencyAmount.fromRawAmount(outputCurrency, outputAmount.toString()),
        )
        : unbuttonWrapFromUbToken(
          signer,
          CurrencyAmount.fromRawAmount(outputCurrency, outputAmount.toString()),
        );
    }
    return null;
  }, [signer, inputAmount, inputCurrency, outputAmount, outputCurrency, networkConfig]);

  const unwrap: () => Promise<ContractReceipt> | null = useCallback(() => {
    if (!signer) return null;
    if (wrapper === Wrapper.button && outputCurrency
      && inputAmount && inputCurrency
      && networkConfig?.wrapperRouters[outputCurrency.address]) {
      // Unwrapping with router (from bToken)
      return buttonUnwrapWithRouter(
        signer,
        CurrencyAmount.fromRawAmount(inputCurrency, inputAmount.toString()),
        networkConfig.wrapperRouters[outputCurrency.address],
      );
    }
    if (inputAmount && inputCurrency) {
      // Unwrapping from bToken
      return (wrapper === Wrapper.button)
        ? buttonUnwrapFromBToken(
          signer,
          CurrencyAmount.fromRawAmount(inputCurrency, inputAmount.toString()),
        )
        : unbuttonUnwrapFromUbToken(
          signer,
          CurrencyAmount.fromRawAmount(inputCurrency, inputAmount.toString()),
        );
    }
    if (outputAmount && inputCurrency) {
      // Unwrapping from underlying
      return (wrapper === Wrapper.button)
        ? buttonUnwrapFromUnderlying(signer, outputAmount.toString(), inputCurrency)
        : unbuttonUnwrapFromUnderyling(signer, outputAmount.toString(), inputCurrency);
    }
    return null;
  }, [signer, inputAmount, inputCurrency, outputAmount, outputCurrency, networkConfig]);

  const exchange = useCallback(() => {
    if (signer && ready && networkConfig) {
      setExchangeProgress({ reason: null, value: null, exchangeStep: 'depositing' });
      let promise: Promise<ContractReceipt> | null = null;
      if (wrapDirection === WrapDirection.wrapping) {
        promise = wrap();
      } else if (wrapDirection === WrapDirection.unwrapping) {
        promise = unwrap();
      }
      if (promise) {
        promise.then((value) => {
          setExchangeProgress({ reason: null, value, exchangeStep: 'depositCompleted' });
        })
          .catch((reason) => {
            console.error('reason:', reason);
            setExchangeProgress({ reason, value: null, exchangeStep: 'error' });
          });
      }
    }
  }, [signer, ready, wrapper, wrapDirection, inputCurrency, inputAmount, outputCurrency]);

  const reset = useCallback(() => {
    setInputAmount(null);
    setOutputAmount(null);
    deleteSessionStorage('inputAmount');
    deleteSessionStorage('outputAmount');
    setExchangeProgress({ reason: null, value: null, exchangeStep: 'start' });
  }, []);

  const updateInputAmountHandler = (iA: BigNumber | null) => {
    setInputAmount(iA);
    setOutputAmount(null);
    writeSessionStorage('inputAmount', iA || '');
    deleteSessionStorage('outputAmount');
  };

  const updateOutputAmountHandler = (oA: BigNumber | null) => {
    setOutputAmount(oA);
    setInputAmount(null);
    writeSessionStorage('outputAmount', oA || '');
    deleteSessionStorage('inputAmount');
  };

  return (
    <ButtonContext.Provider
      value={{
        wrapper,
        // getTokenBalances,
        buttonWrap: () => Promise.reject(Error('Unitialized')),
        wrapDirection,
        flipWrapDirection,
        inputCurrencyList: [...(tokenPairs.get(wrapDirection)?.keys() || [])],
        inputCurrency,
        setInputCurrency: (iC: Token | null) => { setInputCurrency(iC); writeSessionStorage('inputCurrencyAddress', iC?.address || null); },
        inputAmount: calculatedInputAmount,
        setInputAmount: updateInputAmountHandler,
        outputCurrency,
        outputAmount: calculatedOutputAmount,
        setOutputAmount: updateOutputAmountHandler,
        inputCurrencyBalance: tokenBalances?.get(inputCurrency) || null,
        outputCurrencyBalance: tokenBalances?.get(outputCurrency) || null,
        currentExchangeRatio,
        exchangeProgress,
        approve,
        exchange,
        reset,
      }}
    >
      {children}
    </ButtonContext.Provider>
  );
};

ButtonProvider.defaultProps = defaultProps;

export { ButtonProvider };

export default ButtonContext;
