import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    gradient: {
      top: string;
      bottom: string;
    };
  }
}

// A custom theme for this app
export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#912dff',
      dark: '#5a56d6',
    },
    secondary: {
      main: '#2c2c2c',
      dark: '#000',
      contrastText: '#e1e1e1',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#eeeeee',
      paper: '#fff',
      gradient: {
        top: '#FFFFFF',
        bottom: '#F8F8F8',
      },
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: ['Raleway', 'HelveticaNeue', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    subtitle1: {
      fontSize: '1.5rem',
    },
    body1: {
      fontSize: '1.5rem',
    },
    button: {
      textTransform: 'uppercase',
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
  },
});

// ToDo: @fiddlekins - make a dark-theme for wrapper-client
export const darkTheme = lightTheme;
