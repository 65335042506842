import { Token } from '@uniswap/sdk-core';
import { setMulticallAddress } from 'ethers-multicall';
import { Chain, createConfigs } from 'common-client';
import ethereumLogoUrl from 'common-client/dist/assets/images/ethereum-logo.png';
import avalancheLogoUrl from 'common-client/dist/assets/images/avalanche-logo.png';

// Sentinel address for native ETH
export const NATIVE_ETH_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';

export const SUPPORTED_CHAINS = [Chain.Mainnet, Chain.Kovan, Chain.Local, Chain.AVAX];

export const enum Asset {
  AVAX,
  ETH,
  WAVAX,
  USDT,
  AMPL,
  WETH,
  WBTC,
  aAMPL,
  bETH,
  bBTC,
  bWAVAX,
  ubAAMPL,
  ubAMPL
}

export enum Wrapper {
  button='button',
  unbutton='unbutton',
}

export const enum WrapDirection {
  wrapping='wrapping',
  unwrapping='unwrapping'
}

export const assetPairs: { [key in Wrapper]: Asset[][] } = {
  [Wrapper.button]: [
    [Asset.ETH, Asset.bETH],
    [Asset.WETH, Asset.bETH],
    [Asset.WBTC, Asset.bBTC],
    [Asset.AVAX, Asset.bWAVAX],
  ],
  [Wrapper.unbutton]: [
    [Asset.aAMPL, Asset.ubAAMPL],
  ],
};

export type ChainInfo = {
  explorer: string;
  label: string;
  logoUrl: string;
}

export type NetworkConfig = {
  network: {
    name: string;
    chainId: number;
    networkId: number;
  };
  assets: Partial<Record<Asset, Token>>,
  wrapperRouters: {
      // some assets need to go through custom adapter for deposit / withdraw
      // i.e. native assets like ETH need to be wrapped to WETH before depositing
      // map input / output token addresses to deposit / withdraw router address
      [key: string]: string;
  };
  pairs: Map<Wrapper, Map<WrapDirection, Map<Token, Token>>>;
  multicallAddress?: string;
  chainInfo: ChainInfo;
}

export type Config = Partial<Record<Chain, NetworkConfig>>;

const config: Config = {
  [Chain.Mainnet]: {
    network: {
      name: 'mainnet',
      chainId: 1,
      networkId: 1,
    },
    assets: {
      [Asset.ETH]: new Token(1, NATIVE_ETH_ADDRESS, 18, 'ETH', 'Ether'),
      [Asset.USDT]: new Token(1, '0xdac17f958d2ee523a2206206994597c13d831ec7', 6, 'USDT', 'Tether'),
      [Asset.AMPL]: new Token(1, '0xd46ba6d942050d489dbd938a2c909a5d5039a161', 9, 'AMPL', 'Ampleforth'),
      [Asset.WETH]: new Token(1, '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', 18, 'WETH', 'Wrapped Ether'),
      [Asset.WBTC]: new Token(1, '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599', 8, 'WBTC', 'Wrapped BTC'),
      [Asset.aAMPL]: new Token(1, '0x1e6bb68acec8fefbd87d192be09bb274170a0548', 9, 'aAMPL', 'Aave interest bearing AMPL'),
      [Asset.bETH]: new Token(1, '0x125c7b36bea62ba3266257521667154446412921', 18, 'bETH', 'Button ETH'),
      [Asset.bBTC]: new Token(1, '0x69d4d3629e1aFEE0C4E75B6B345B482979A77112', 8, 'bBTC', 'Button BTC'),
      [Asset.ubAAMPL]: new Token(1, '0xF03387d8d0FF326ab586A58E0ab4121d106147DF', 18, 'ubAAMPL', 'Unbuttoned AAVE AMPL'),
    },
    pairs: new Map(),
    wrapperRouters: {
      [NATIVE_ETH_ADDRESS]: '0xB08c5e2E8E73d0FB9842171496B2da04E8E80A0D',
    },
    chainInfo: {
      explorer: 'https://etherscan.io/',
      label: 'Ethereum',
      logoUrl: ethereumLogoUrl,
    },
  },
  [Chain.Kovan]: {
    network: {
      name: 'kovan',
      chainId: 42,
      networkId: 42,
    },
    assets: {
      [Asset.ETH]: new Token(1, NATIVE_ETH_ADDRESS, 18, 'ETH', 'Ether'),
      [Asset.USDT]: new Token(42, '0xaff4481d10270f50f203e0763e2597776068cbc5', 18, 'USDT', 'Tether'),
      [Asset.AMPL]: new Token(42, '0x3e0437898a5667a4769b1ca5a34aab1ae7e81377', 9, 'AMPL', 'Ampleforth'),
      [Asset.WETH]: new Token(42, '0xd0a1e359811322d97991e03f863a0c30c2cf029c', 18, 'WETH', 'Wrapped Ether'),
      [Asset.WBTC]: new Token(42, '0xa0a5ad2296b38bd3e3eb59aaeaf1589e8d9a29a9', 8, 'WBTC', 'Wrapped BTC'),
      [Asset.aAMPL]: new Token(42, '0x3e0437898a5667a4769b1ca5a34aab1ae7e81377', 9, 'aAMPL', 'Aave interest bearing AMPL'),
      [Asset.bETH]: new Token(42, '0x80ee392d7f9fe19ccfdbc5365983e7fc4c68ca4e', 18, 'bETH', 'Button ETH'),
      [Asset.bBTC]: new Token(42, '0x414fec543d85f2ef95a98221ec214cec745862e0', 8, 'bBTC', 'Button BTC'),
      [Asset.ubAMPL]: new Token(42, '0xebb66682be59c7de222afd7e884c065b5f1ca9ca', 18, 'ubAMPL', 'Unbuttoned AMPL'),
    },
    pairs: new Map(),
    wrapperRouters: {
      [NATIVE_ETH_ADDRESS]: '0xd5A424b9132719B9E056858D2F3445a855Cfc476',
    },
    chainInfo: {
      explorer: 'https://kovan.etherscan.io/',
      label: 'Kovan',
      logoUrl: ethereumLogoUrl,
    },
  },
  [Chain.AVAX]: {
    network: {
      name: 'avalanche',
      chainId: 43114,
      networkId: 43114,
    },
    assets: {
      [Asset.AVAX]: new Token(43114, NATIVE_ETH_ADDRESS, 18, 'AVAX', 'Avalanche'),
      [Asset.bWAVAX]: new Token(43114, '0x9f61aE42c01698aC35AedeF651B0FE5E407bC6A0', 18, 'bWAVAX', 'Button WAVAX'),
      [Asset.WBTC]: new Token(43114, '0x50b7545627a5162f82a992c33b87adc75187b218', 8, 'WBTC', 'Wrapped BTC'),
      [Asset.bBTC]: new Token(43114, '0x9bFE32D18e66ffAF6dcB0306AE7D24F768469f91', 8, 'bBTC', 'Button BTC'),
      [Asset.WETH]: new Token(43114, '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab', 18, 'WETH', 'Wrapped Ether'),
      [Asset.bETH]: new Token(43114, '0x227d7A0e2586A5bFdA7f32aDF066d20D1bfDfDfb', 18, 'bETH', 'Button ETH'),
    },
    pairs: new Map(),
    wrapperRouters: {
      [NATIVE_ETH_ADDRESS]: '0x43a7D32e702aB2e553D0e507A7CE2a23E0141a1f',
    },
    chainInfo: {
      explorer: 'https://snowtrace.io/',
      label: 'Avalanche',
      logoUrl: avalancheLogoUrl,
    },
  },
  // for e2e testing
  [Chain.Local]: {
    network: {
      name: 'local',
      chainId: 1337,
      networkId: 1337,
    },
    // note these addresses are dependent on the address used to locally deploy contracts
    // this is configured to work with the e2e package's configured test address and deploy scripts
    assets: {
      [Asset.ETH]: new Token(1337, NATIVE_ETH_ADDRESS, 18, 'ETH', 'Ether'),
      [Asset.USDT]: new Token(1337, '0x1a1e5573e491464D1a32e10C20a9D61dd35bfd18', 18, 'USDT', 'Tether'),
      [Asset.WETH]: new Token(1337, '0x286D98538824C007Ed18452237358DeE26dcC9cb', 18, 'WETH', 'Wrapped Ether'),
      [Asset.WBTC]: new Token(1337, '0xFd928bBee42338713cea9e173D2C1DC45C929cf3', 8, 'WBTC', 'Wrapped BTC'),
      [Asset.bETH]: new Token(1337, '0x93BB06BF5FB5648cD30E06CccE510784219F0a71', 18, 'bETH', 'Button ETH'),
      [Asset.bBTC]: new Token(1337, '0xf45F35ca9Ed1d847EcD84471f0Be26dedC3bB508', 8, 'bBTC', 'Button BTC'),
    },
    pairs: new Map(),
    wrapperRouters: {
      [NATIVE_ETH_ADDRESS]: '0x0370C72bC37A18d7662C2F55d37F4B9a8F3A4519',
    },
    chainInfo: {
      explorer: '',
      label: 'local',
      logoUrl: ethereumLogoUrl,
    },
  },
};

// since local network not configured by the ethers-multicall package
// we have to set our custom address on local networks
setMulticallAddress(1337, '0x193732C3cf23f7251C2dBcC954D2358FA765390C');

Object.values(config).forEach((networkConfig: NetworkConfig) => {
  Object.entries(assetPairs).forEach(([key, value]) => {
    const wrapper: Wrapper = Wrapper[key as keyof typeof Wrapper];
    const tokenPairs = value.map(
      (assetList: Asset[]) => assetList.map((asset) => networkConfig.assets[asset]),
    ).filter((tokenpair: (Token|undefined)[]) => tokenpair.every(
      (token) => token !== undefined,
    )) as [Token, Token][];
    const wrappingMap = new Map<Token, Token>(tokenPairs);
    const unwrappingMap = new Map<Token, Token>(
      tokenPairs.map((pair) => pair.reverse() as [Token, Token]),
    );

    const innerMap: Map<WrapDirection, Map<Token, Token>> = new Map([
      [WrapDirection.wrapping, wrappingMap],
      [WrapDirection.unwrapping, unwrappingMap],
    ]);

    networkConfig.pairs.set(wrapper, innerMap);
  });
});

function getConfig(chain: Chain): NetworkConfig | null {
  if (chain === Chain.Unsupported) {
    return null;
  }
  return config[chain] || null;
}

const { ConfigContext, ConfigProvider } = createConfigs(getConfig);
export { ConfigContext, ConfigProvider };
