import React, { useContext } from 'react';
import { ExchangeForm } from 'components/ExchangeForm';
import { ConfigContext, Wrapper } from 'config';
import { useHistory } from 'react-router-dom';
import { Web3Context, LoadingCard } from 'common-client';
import ButtonContext from '../contexts/ButtonContext';

export const Exchange = React.memo(() => {
  const { ready } = useContext(Web3Context);
  const { networkConfig } = useContext(ConfigContext);
  const {
    wrapper,
    wrapDirection,
    flipWrapDirection,
    inputCurrencyList,
    inputCurrency,
    setInputCurrency,
    inputAmount,
    setInputAmount,
    outputCurrency,
    outputAmount,
    setOutputAmount,
    inputCurrencyBalance,
    outputCurrencyBalance,
    // currentExchangeRatio,
    exchangeProgress,
    approve,
    exchange,
    reset,
  } = useContext(ButtonContext);
  const history = useHistory();

  // console.log('signer', signer);
  // console.log('ready', ready);
  // console.log('address', address);
  // console.log('chain', chain);
  //
  // console.log(wrapDirection);
  // console.log(setWrapDirection);

  // console.log('currentExchangeRatio:', currentExchangeRatio);

  // ToDo: Add inputError to ExchangeForm if input exceeds balance
  //  Also disableSubmit if inputError != null

  const submitHandler = () => {
    switch (exchangeProgress.exchangeStep) {
      case 'start': approve(); break;
      case 'collateralApproved': exchange(); break;
      default: reset(); break;
    }
  };

  // ToDo: Figure out how to make this work elegantly without glitching
  // const setOutputAmountHandler = useCallback((amount: BigNumber | null) => {
  //   if (!amount) {
  //     setInputAmount(null);
  //   } else if (currentExchangeRatio) {
  //     setInputAmount(amount.mul(currentExchangeRatio.to).div(currentExchangeRatio.from));
  //   }
  // }, [currentExchangeRatio]);
  // const setOutputAmountHandler = () => {};

  switch (exchangeProgress.exchangeStep) {
    case 'collateralApproving':
    case 'collateralApproved':
    case 'depositing':
    case 'depositCompleted':
      return (
        <LoadingCard
          stage={exchangeProgress.exchangeStep}
          displayAmount={
            ((outputAmount && outputCurrency)
              && outputAmount)
          }
          displayCurrency={outputCurrency || undefined}
          walletAmount={
            ((outputCurrencyBalance && outputCurrency)
              && outputCurrencyBalance)
          }
          transactionId={exchangeProgress.value?.transactionHash}
          networkName={networkConfig?.chainInfo.label}
          buttonHandler={submitHandler}
        />
      );
    case 'error':
      return (
        <LoadingCard
          stage={exchangeProgress.exchangeStep}
          message={exchangeProgress.reason.message}
          buttonHandler={submitHandler}
        />
      );
    default:
      return (
        <ExchangeForm
          wrapper={wrapper}
          setWrapper={(nextWrapper: Wrapper) => { history.replace(`/${nextWrapper.toString()}`); }} // ToDo - Get rid of setWrapper?
          inputCurrency={inputCurrency}
          setInputCurrency={setInputCurrency}
          inputCurrencyList={inputCurrencyList}
          outputCurrency={outputCurrency}
          inputAmount={inputAmount}
          setInputAmount={setInputAmount}
          inputBalance={inputCurrencyBalance}
          outputAmount={outputAmount}
          setOutputAmount={setOutputAmount}
          wrapDirection={wrapDirection}
          toggleWrapDirection={flipWrapDirection}
          disableSubmit={!ready}
          submitHandler={submitHandler}
        />
      );
  }
});
