import {
  BigNumber, ContractReceipt, ethers, Signer,
} from 'ethers';
import { Contract, Provider as MulticallProvider } from 'ethers-multicall';
import { CurrencyAmount, Token } from '@uniswap/sdk-core';
import { NATIVE_ETH_ADDRESS } from './config';
import {
  // eslint-disable-next-line camelcase
  ERC20__factory, ButtonToken__factory, UnbuttonToken__factory, ButtonTokenWethRouter__factory,
} from './types';

export async function ethBalance(
  signer: Signer,
): Promise<BigNumber[]> {
  return signer.getBalance().then((bn) => [bn]);
}

export async function balanceOfMulti(
  provider: MulticallProvider,
  signer: Signer,
  tokens: Token[],
): Promise<BigNumber[]> {
  const address = await signer.getAddress();

  return provider.all(tokens.map((token) => {
    if (token.address === NATIVE_ETH_ADDRESS) {
      return provider.getEthBalance(address);
    }
    const erc20 = new Contract(token.address, ERC20__factory.abi);
    return erc20.balanceOf(address);
  })).then((results: string[]) => results.map((result) => BigNumber.from(result)));
}

export async function totalSupplyMulti(
  provider: MulticallProvider,
  signer: Signer,
  tokens: Token[],
): Promise<BigNumber[]> {
  return provider.all(tokens.map((token) => {
    const erc20 = new Contract(token.address, ERC20__factory.abi);
    return erc20.totalSupply();
  })).then((results: string[]) => results.map((result) => BigNumber.from(result)));
}

export async function wrapperToUnderlyingMulti(
  provider: MulticallProvider,
  signer: Signer,
  amounts: BigNumber[],
  wrapperTokens: Token[],
): Promise<BigNumber[]> {
  return provider.all(wrapperTokens.map((wrapperToken: Token, index: number) => {
    const wrapperTokenContract = new Contract(wrapperToken.address, UnbuttonToken__factory.abi);
    return wrapperTokenContract.wrapperToUnderlying(amounts[index]);
  })).then((results: string[]) => results.map((result) => BigNumber.from(result)));
}

export async function approveWrapping(
  signer: Signer,
  underlyingAmount: CurrencyAmount<Token>,
  wrappingToken: Token,
): Promise<boolean> {
  if (underlyingAmount.currency.address === NATIVE_ETH_ADDRESS) {
    // dont need to approve raw ETH wraps
    return true;
  }

  const erc20 = ERC20__factory.connect(underlyingAmount.currency.address, signer);
  const approved = await erc20.allowance(await signer.getAddress(), wrappingToken.address);
  if (approved.gte(underlyingAmount.quotient.toString())) {
    // Already approved;
    return true;
  }
  const tx = await erc20.approve(wrappingToken.address, ethers.constants.MaxUint256);
  const receipt = await tx.wait();
  // note ethereum status 1 = success, status 0 = failure
  return receipt.status === 1;
}

export async function approveRouter(
  signer: Signer,
  underlyingAmount: CurrencyAmount<Token>,
  routerAddress: string,
): Promise<boolean> {
  const erc20 = ERC20__factory.connect(underlyingAmount.currency.address, signer);
  const approved = await erc20.allowance(await signer.getAddress(), routerAddress);
  if (approved.gte(underlyingAmount.quotient.toString())) {
    // Already approved;
    return true;
  }
  const tx = await erc20.approve(routerAddress, ethers.constants.MaxUint256);
  const receipt = await tx.wait();
  // note ethereum status 1 = success, status 0 = failure
  return receipt.status === 1;
}

export async function buttonWrapWithRouter(
  signer: Signer,
  amount: string,
  buttonToken: Token,
  routerAddress: string,
): Promise<ContractReceipt> {
  const router = ButtonTokenWethRouter__factory.connect(routerAddress, signer);
  const tx = await router.deposit(buttonToken.address, { value: amount });
  return tx.wait();
}

export async function buttonWrapFromUnderlying(
  signer: Signer,
  underylingAmount: string,
  buttonToken: Token,
): Promise<ContractReceipt> {
  const buttonTokenContract = ButtonToken__factory.connect(buttonToken.address, signer);
  const tx = await buttonTokenContract.deposit(underylingAmount);
  return tx.wait();
}

export async function buttonWrapFromBToken(
  signer: Signer,
  buttonTokenAmount: CurrencyAmount<Token>,
): Promise<ContractReceipt> {
  const buttonTokenContract = ButtonToken__factory.connect(
    buttonTokenAmount.currency.address,
    signer,
  );
  const tx = await buttonTokenContract.mint(buttonTokenAmount.quotient.toString());
  return tx.wait();
}

export async function buttonUnwrapWithRouter(
  signer: Signer,
  buttonTokenAmount: CurrencyAmount<Token>,
  routerAddress: string,
): Promise<ContractReceipt> {
  const router = ButtonTokenWethRouter__factory.connect(routerAddress, signer);
  const tx = await router.burn(
    buttonTokenAmount.currency.address,
    buttonTokenAmount.quotient.toString(),
  );
  return tx.wait();
}

export async function buttonUnwrapFromUnderlying(
  signer: Signer,
  amount: string,
  buttonToken: Token,
): Promise<ContractReceipt> {
  const buttonTokenContract = ButtonToken__factory.connect(buttonToken.address, signer);
  const tx = await buttonTokenContract.withdraw(amount);
  return tx.wait();
}

export async function buttonUnwrapFromBToken(
  signer: Signer,
  buttonTokenAmount: CurrencyAmount<Token>,
): Promise<ContractReceipt> {
  const buttonTokenContract = ButtonToken__factory.connect(
    buttonTokenAmount.currency.address,
    signer,
  );
  const tx = await buttonTokenContract.burn(buttonTokenAmount.quotient.toString());
  return tx.wait();
}

export async function unbuttonWrapFromUnderlying(
  signer: Signer,
  underylingAmount: string,
  unbuttonToken: Token,
): Promise<ContractReceipt> {
  const unbuttonTokenContract = UnbuttonToken__factory.connect(unbuttonToken.address, signer);
  const tx = await unbuttonTokenContract.deposit(underylingAmount);
  return tx.wait();
}

export async function unbuttonWrapFromUbToken(
  signer: Signer,
  unbuttonTokenAmount: CurrencyAmount<Token>,
): Promise<ContractReceipt> {
  const unbuttonTokenContract = UnbuttonToken__factory.connect(
    unbuttonTokenAmount.currency.address,
    signer,
  );
  const tx = await unbuttonTokenContract.mint(unbuttonTokenAmount.quotient.toString());
  return tx.wait();
}

export async function unbuttonUnwrapFromUnderyling(
  signer: Signer,
  underylingAmount: string,
  unbuttonToken: Token,
): Promise<ContractReceipt> {
  const unbuttonTokenContract = UnbuttonToken__factory.connect(unbuttonToken.address, signer);
  const tx = await unbuttonTokenContract.withdraw(underylingAmount);
  return tx.wait();
}

export async function unbuttonUnwrapFromUbToken(
  signer: Signer,
  unbuttonTokenAmount: CurrencyAmount<Token>,
): Promise<ContractReceipt> {
  const unbuttonTokenContract = UnbuttonToken__factory.connect(
    unbuttonTokenAmount.currency.address,
    signer,
  );
  const tx = await unbuttonTokenContract.burn(unbuttonTokenAmount.quotient.toString());
  return tx.wait();
}
