import React from 'react';
import { Box, BoxProps, styled } from '@mui/material';

const LogoBox = styled(Box, { name: 'TrancheBarContainerBox' })<BoxProps>(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
}));

const ButttonB = styled('b', { name: 'ButttonB' })(() => ({
  fontFamily: 'Montserrat Alternates',
  fontWeight: 900,
  fontSize: '2.0rem',
}));

const WrapperSup = styled('sup', { name: 'WrapperSup' })(() => ({
  verticalAlign: 'super',
  fontSize: 'smaller',
}));

export interface LogoProps {
  /**
   * Color of LogoEmblem and LogoText
   */
  color: string;
  /**
   * Callback to fire when logo is clicked
   */
  onClick: () => void;
}

export function Logo({ color, onClick }: LogoProps) {
  return (
    <LogoBox
      height="100%"
      display="flex"
      alignItems="center"
      onClick={onClick}
      color={color}
    >
      <ButttonB>button</ButttonB>
      <WrapperSup>Wrapper</WrapperSup>
    </LogoBox>
  );
}
