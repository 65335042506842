import React from 'react';
import 'App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import { withProfiler } from '@sentry/react';
import { Exchange } from 'pages/Exchange';
import { TokenListProvider, Web3Provider, WalletConnectionProvider } from 'common-client';
import { TopBarWrapper } from 'wrappers/TopBarWrapper';
import { ButtonProvider } from 'contexts/ButtonContext';
import { Wrapper, ConfigProvider, SUPPORTED_CHAINS } from 'config';
import { Box, BoxProps, styled } from '@mui/material';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ethereum: any;
  }
}

window.ethereum = window.ethereum || {};

const AppBox = styled(Box, { name: 'AppBox' })<BoxProps>(({ theme }) => ({
  minHeight: '100vh',
  background: `linear-gradient(-140deg, ${theme.palette.background.gradient.top} 0%, ${theme.palette.background.gradient.bottom} 100%)`,
}));

const BodyBox = styled(Box, { name: 'BodyBox' })<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
}));

function App() {
  const queryClient = new QueryClient();

  return (
    <Router>
      <Web3Provider>
        <WalletConnectionProvider supportedChains={SUPPORTED_CHAINS}>
          <ConfigProvider>
            <TokenListProvider>
              <AppBox>
                <TopBarWrapper options={[]} links={[]} setTheme={() => {}} />
                <BodyBox
                  display="flex"
                  flexDirection="column"
                >
                  <QueryClientProvider client={queryClient}>
                    <Switch>
                      <Route path="/button">
                        <ButtonProvider wrapper={Wrapper.button}>
                          <Exchange />
                        </ButtonProvider>
                      </Route>
                      <Route path="/unbutton">
                        <ButtonProvider wrapper={Wrapper.unbutton}>
                          <Exchange />
                        </ButtonProvider>
                      </Route>
                      <Route path="/">
                        <Redirect to="/button" />
                      </Route>
                    </Switch>
                  </QueryClientProvider>
                </BodyBox>
              </AppBox>
            </TokenListProvider>
          </ConfigProvider>
        </WalletConnectionProvider>
      </Web3Provider>
    </Router>
  );
}

export default withProfiler(App);
