import React, { useContext } from 'react';
import { BigNumber } from 'ethers';
import { CurrencyAmount, Token } from '@uniswap/sdk-core';
import { CurrencyInputField, TokenListContext, SubmitButton } from 'common-client';
import {
  Box, BoxProps, Fab, styled, Tab, TabProps, Tabs, TabsProps,
} from '@mui/material';
import { CompareArrows } from '@mui/icons-material';
import { WrapDirection, Wrapper } from 'config';

const ExchangeFormDiv = styled('div', { name: 'ExchangeFormDiv' })(() => ({
  width: '100%',
  maxWidth: 450,
  borderRadius: 30,
  boxShadow: '0px 0px 25px -3px rgb(0 0 0 / 8%)',
}));

const ExchangeFormBox = styled(Box, { name: 'ExchangeFormBox' })<BoxProps>(() => ({
  borderBottomLeftRadius: 30,
  borderBottomRightRadius: 30,
  padding: 20,
  border: '1px solid #efefef',
  '& > :not(:first-child)': {
    marginTop: 30,
  },
}));

const ExchangeFormTabs = styled(Tabs, { name: 'ExchangeFormTabs' })<TabsProps>(({ theme }) => ({
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  border: '1px solid #efefef',
  borderBottomWidth: 0,
  backgroundColor: theme.palette.secondary.dark,
  '&.MuiTabs-indicator': {
    backgroundColor: theme.palette.secondary.light,
  },
}));

const ExchangeFormTab = styled(Tab, { name: 'ExchangeFormTab' })<TabProps>(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.secondary.contrastText,
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const ModeIconWrapperBox = styled(Box, { name: 'ModeIconWrapperDiv' })<BoxProps>(() => ({
  transition: 'transform 200ms',
}));

export interface ExchangeFormProps {
    /**
     * Wrapper
     */
    wrapper: Wrapper;
    /**
     * setWrapper callback
     */
    setWrapper: (wrapper: Wrapper) => void;
    /**
     * Input currency
     */
    inputCurrency: Token | null;
    /**
     * callback for setting inputCurrency
     */
    setInputCurrency: (inputCurrency: Token) => void;
    /**
     * Input currency List
     */
    inputCurrencyList: Array<Token>;
    /**
     * Output currency
     */
    outputCurrency: Token | null;
    /**
     * Input amount
     */
    inputAmount: BigNumber | null;
    /**
     * Callback for setting input amount
     */
    setInputAmount: (inputAmount: BigNumber | null) => void;
    /**
     * Wallet balance of current input currency
     */
    inputBalance: BigNumber | null;
    /**
     * Output amount
     */
    outputAmount: BigNumber | null;
    /**
     * Callback for setting output amount
     */
    setOutputAmount: (inputAmount: BigNumber | null) => void;
    /**
     * wrapDirection
     */
    wrapDirection: WrapDirection;
    /**
     * Callback for toggling wrapDirection
     */
    toggleWrapDirection: () => void;
    /**
     * Optional Boolean argument to disable the Submit Button by default
     */
    // ToDo: @Fiddlekins: Why do we have default-prop complaints everywhere in wrapper-client now?
    // eslint-disable-next-line react/require-default-props
    disableSubmit?: boolean;
    /**
     * onClickHandler for the submit button
     */
    // ToDo: @Fiddlekins: Why do we have default-prop complaints everywhere in wrapper-client now?
    // eslint-disable-next-line react/require-default-props
    submitHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const tabConfigs = [
  {
    wrapper: Wrapper.button,
    name: 'Button',
  },
  {
    wrapper: Wrapper.unbutton,
    name: 'Unbutton',
  },
];

const SUBMIT_BUTTON_TEXT = 'EXCHANGE';

function a11yProps(wrapper: Wrapper) {
  return {
    id: `simple-tab-${wrapper}`,
    'aria-controls': `simplze-tabpanel-${wrapper}`,
  };
}

export function ExchangeForm({
  wrapper,
  setWrapper,
  inputCurrency,
  setInputCurrency,
  inputCurrencyList,
  outputCurrency,
  inputAmount = null,
  setInputAmount,
  inputBalance,
  outputAmount,
  setOutputAmount,
  wrapDirection,
  toggleWrapDirection,
  disableSubmit = false,
  submitHandler,
}: ExchangeFormProps) {
  const { getLogoURI } = useContext(TokenListContext);

  const onChange = (_event: any, newValue: Wrapper) => {
    setWrapper(newValue);
  };

  const onInputCurrencySelect = (currency: Token) => {
    setInputCurrency(currency);
  };

  const balanceError = (inputAmount && inputBalance && inputAmount.gt(inputBalance) && 'Balance Exceeded') || null;

  return (
    <ExchangeFormDiv>
      <ExchangeFormTabs
        value={wrapper} // ToDo: Fix later
        onChange={onChange}
        variant="fullWidth"
      >
        {tabConfigs.map((tabConfig) => (
          <ExchangeFormTab
            key={tabConfig.wrapper}
            value={tabConfig.wrapper}
            label={tabConfig.name}
            data-testid="tab"
            {...a11yProps(tabConfig.wrapper)}
          />
        ))}
      </ExchangeFormTabs>
      <ExchangeFormBox
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        alignItems="stretch"
        boxSizing="content-box"
      >
        {inputCurrency
                && (
                <CurrencyInputField
                  currency={inputCurrency}
                  currencies={inputCurrencyList}
                  logoURIs={inputCurrencyList.map((currency) => getLogoURI(currency))}
                  amount={inputAmount}
                  label="Enter Amount"
                  onUpdateAmount={(value: BigNumber | null) => setInputAmount(value)}
                  onCurrencySelect={onInputCurrencySelect}
                  helperText={inputBalance && `Input Balance: ${CurrencyAmount.fromRawAmount(inputCurrency, inputBalance.toString()).toExact()}`}
                  error={balanceError}
                  data-testid="currency-input"
                />
                )}
        <Fab
          color="primary"
          aria-label="mode-toggle"
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          onClick={toggleWrapDirection}
        >
          <ModeIconWrapperBox
            display="flex"
            style={{ transform: `rotate(${wrapDirection === WrapDirection.wrapping ? 90 : -90}deg)` }}
          >
            <CompareArrows />
          </ModeIconWrapperBox>
        </Fab>
        {outputCurrency
                && (
                <CurrencyInputField
                  currency={outputCurrency}
                  amount={outputAmount}
                  label="Output Amount"
                  onUpdateAmount={(value: BigNumber | null) => setOutputAmount(value)}
                />
                )}
        <SubmitButton
          label={SUBMIT_BUTTON_TEXT}
          disabled={disableSubmit || !!balanceError || inputAmount == null || inputAmount.eq(0)}
          clickHandler={submitHandler}
          data-testid="submit-button"
        />
      </ExchangeFormBox>
    </ExchangeFormDiv>
  );
}
