import React, { useContext } from 'react';
import { Web3Context, TopBar } from 'common-client';
import { ConfigContext } from 'config';
import { LogoWrapper } from 'wrappers/LogoWrapper';
import { Theme, useTheme } from '@mui/material';

export interface TopBarWrapperProps {
  /**
   * Menu Button Options
   */
  options: Array<string>;
  /**
   * Menu Button Links
   */
  links: Array<string>;
  /**
   * Callback to set the theme the app uses
   */
  setTheme: (theme: Theme) => void;
}

export function TopBarWrapper({
  options, links, setTheme,
}: TopBarWrapperProps) {
  const theme = useTheme();

  const { selectWallet, disconnectWallet, address } = useContext(Web3Context);
  const { networkConfig } = useContext(ConfigContext);

  // ToDo: Establish light and dark themes for wrapper-client
  const darkTheme = theme;
  const lightTheme = theme;
  return (
    <TopBar
      options={options}
      links={links}
      toggleTheme={() => setTheme(theme.palette.mode === 'light' ? darkTheme : lightTheme)}
      selectWallet={selectWallet}
      disconnectWallet={disconnectWallet}
      address={address}
      logo={<LogoWrapper color={theme.palette.text.primary} />}
      chainLogoUrl={networkConfig?.chainInfo.logoUrl}
      chainLabel={networkConfig?.chainInfo.label || 'Unknown network'}
    />
  );
//  chainLogoUrl={networkConfig?.chainInfo.logoUrl}
//       chainLabel={networkConfig ? networkConfig.chainInfo.label : 'No network'}
}
