import React from 'react';
import { useHistory } from 'react-router-dom';
import { Logo } from 'components/Logo';

export interface LogoWrapperProps {
  /**
   * Color of LogoEmblem and LogoText
   */
  color: string;
}

export function LogoWrapper({ color } : LogoWrapperProps) {
  const history = useHistory();
  const handleHomeClick = () => {
    history.push('/');
  };

  return (
    <Logo color={color} onClick={handleHomeClick} />
  );
}
